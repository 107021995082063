

































































































































import Bop from "@/models/Bop";
import OperationService from "@/services/OperationService";
import {
  BopModelEnum,
  CoefficientTypeEnum,
  OperationType,
} from "@/utils/Enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import BopService from "@/services/BopService";
import CoefficientService from "@/services/CoefficientService";
import Coefficient from "@/models/Coefficient";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import BopModelService from "@/services/BopModelService";
import OperationGroup from "@/models/OperationGroup";
import OperationTypeService from "@/services/OperationTypeService";
import { evaluate } from "mathjs";
import BopModel from "@/models/BopModel";

const INITIALIZED_GLOBAL_COEFFICIENTS:Array<string> = ['K2PEINT','K3PEINT']

@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
    OperationComponent: () =>
      import("@/components/BopSetup/shared/OperationComponent.vue"),
  },
})
export default class OperationPrincipalStepOpFamily extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isEditable(): boolean {
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  }
  private haveT1Coefficients: boolean = false;
  private operationGroups: Array<any> = [];
  private operationGroupCoefficientFields: Array<any> = [];
  private selectedCoeffGroup: any = {};
  // operation groupe coefficient fields selected values
  private ogCoefficientFieldsSelectedVals: Array<any> = [];
  private globalCoefficients: any = {};
  private unitValuePointIsDefinedByCoefficient: boolean = false;
  private get isLoaded(): boolean {
    const length = this.operationGroups.filter(
      (og: OperationGroup) => !og.canBeDeleted
    ).length;
    // console.log("length");
    // console.log(length);
    return this.operationGroupCounter === length;
  }
  private columnsPerFamily: any = {};
  private coefficientsPerFamily: any = {};
  private selectedGlobalCoefficients: any = {};
  /**
   * Contains the total points of each group
   * operationGroupsTotalPoints[groupId] = totalPoints of the group
   */
  private operationGroupsTotalPoints: any = {};
  /**
   * operationGroupOperationFamilyDictionary[operationFamilyKey]=operationGroupId
   */
  private operationGroupOperationFamilyDictionary: any = {};

  private get bopShort(): String {
    return `${this.bop.siteName} - ${this.bop.bopModelName?.toString().toLowerCase()} ${this.bop.bopNumber ? " # ":""}${this.bop.bopNumber ?? ""}`;
  }

  private get allTotalPoints() {
    return this.bop.totalOperationPrincipalPoints;
  }
  private get selectedBopModel(): BopModel | null {
    return this.$store.state.selectedBopModel;
  }
  private get bop() {
    return this.$store.state.bop;
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private get isQuantityVisible(): boolean {
    return this.bop.bopModelKey !== BopModelEnum.Echafaudage;
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get isEchafaudage() {
    return this.selectedBopModel && this.selectedBopModel.key === BopModelEnum.Echafaudage;
  }
  private operationGroupCounter = 0;
  private get isBopReadOnly(): boolean {
    return (
      Bop.readOnlyStatuses.includes(this.bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }
  /**
   * methods
   *
   * */
  private onFullyLoaded(oladValue, newValue) {
    this.operationGroupCounter++;
    // console.log("Loaded ;)");
    // if(this.isLoaded){
    // console.log("all groups are loaded");
    this.$emit("fully-loaded");
    // }
  }
  private removeOperationGroup(
    operationGroupIndex: number,
    operationGroupId: string
  ) {
    this.operationGroups.splice(operationGroupIndex, 1);
    this.$store.commit("removeBopOperationPrincipalGroup", operationGroupId);
  }
  private onGlobalCoefficientSelectChange($event: any, coeff: any) {
    Vue.set(this.selectedGlobalCoefficients, coeff.coeffKey, $event);
    this.$forceUpdate();
  }
  private addOperationGroup() {
    let operationGroup = new OperationGroup();
    operationGroup.number = this.operationGroups.length + 1;
    this.ogCoefficientFieldsSelectedVals.push(...[this.selectedCoeffGroup]);
    // console.log( this.operationGroups);
    this.bop.operationPrincipalGroups.push(operationGroup);
    this.operationGroups.push(operationGroup);
  }
  private async loadSelectedGlobalCoefficients() {
    // console.log(this.bop.selectedGlobalCoefficients);
    for (let key in this.bop.selectedGlobalCoefficients) {
      this.$set(
        this.selectedGlobalCoefficients,
        key,
        this.bop.selectedGlobalCoefficients[key].coefficientOptionId
      );
    }
    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      this.bop.selectedGlobalCoefficients
    );
  }

  private onOperationChange(operationDictionary: any) {
    // console.group("Operation Principal change");
    // console.log(operationDictionary);
    const operationGroupIndex = this.bop.operationPrincipalGroups.findIndex(
      (og) => og.id === operationDictionary.key
    );
    const operations = operationDictionary.value;
   this.$store.commit('changeBopOperationPrincipal',{operationGroupIndex,operations})
    // console.groupEnd();
  }
  private async onTotalPointsChanged(totalPoints: any) {
    this.$set(
      this.operationGroupsTotalPoints,
      totalPoints.key,
      totalPoints.value
    );
    this.calculate();
  }
  private calculate() {
    if (!this.isEditable ||!this.selectedBopModel) return;
    // console.group("onOperationGroupsTotalPoints");
    const calculusFunction = this.selectedBopModel.functionTotalUnitPoints;
    // console.log(calculusFunction);
    const totalPoints = Object.values(this.operationGroupsTotalPoints);
    // console.log(totalPoints);
    if(!totalPoints.length) {
        this.$store.commit("changeTotalOperationPrincipalPoints", 0);
        return
    }
    if (!calculusFunction) return;
    const sum = <number>(
      totalPoints.reduce(
        (accumulator, currentValue) =>
          <number>accumulator + <number>currentValue
      )
    );
    if (calculusFunction === "{sum}") {
      const result = Math.round((sum + Number.EPSILON) * 100) / 100;
      this.$store.commit("changeTotalOperationPrincipalPoints", result);
      return;
    }
    const regex = /{[\w.]*}/gm;
    let m;
    let formulaElements: Array<any> = [];
    while ((m = regex.exec(calculusFunction)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      // m.forEach((match, groupIndex) =>
      for (let i = 0; i < m.length; i++) {
        const match = m[i];
        //   console.log(`Found match, group ${groupIndex}: ${match}`);
        const path: string = match.replace(/{|}/gm, "");
        //replacing the {key} with the corresponding value
        let value = "";
        if (path.indexOf("globalCoefficient") !== -1) {
          const lastIndexOfDot = path.indexOf(".");
          const key = path.substring(lastIndexOfDot + 1);
          /**
           * if the coefficient is not yet selectionned no need to continue further calculus and parsing
           */
          if (!this.$store.state.selectedGlobalCoefficients[key]) return;
          value = this.$store.state.selectedGlobalCoefficients[key].value;
          //  console.log(value);
        } else if (path.indexOf("operationFamilyTotal") !== -1) {
          const lastIndexOfDot = path.indexOf(".");
          const key = path.substring(lastIndexOfDot + 1);
          const ogId = this.operationGroupOperationFamilyDictionary[key];
          value = this.operationGroupsTotalPoints[ogId];
        } else if (path === "sum") {
          value = sum.toString();
        }
        // console.log(`path: ${path} value:${value}`);

        if (!value) {
            value = "0";
        }
        formulaElements.push({
          key: match,
          value,
        });
      }
    }
    let equation = calculusFunction;
    // console.log(equation);
    formulaElements.forEach((el: any) => {
      equation = equation.replace(el.key, el.value);
    });
    // console.log(equation);
    let result = evaluate(equation);
    result = Math.round((result + Number.EPSILON) * 100) / 100;
    this.$store.commit("changeTotalOperationPrincipalPoints", result);
    // console.groupEnd();
  }
  private async loadData() {
    await this.loadSelectedGlobalCoefficients();
    const operationFamilies = (
      await OperationTypeService.ListOperationFamiliesAndCoefficients(
        this.bop.bopModelId ?? "",
        this.$i18n.locale
      )
    ).data;
    const operationGroupCoefficientFields: Array<any> = [];
    const globalCoefficients: any = {};
    let columnsPerFamily: any = {};
    let coefficientsPerFamily: any = {};
    // if theres no operation group or all the operation groups are to be deleted
    const isFirstTime =
      this.bop.operationPrincipalGroups.length === 0 ||
      this.bop.operationPrincipalGroups.every(
        (og: OperationGroup) => og.canBeDeleted
      );
    // console.log(operationFamilies);
    // console.group("INSIDE THE LOOP");
    let operationGroups: Array<OperationGroup> = [];
    for (let j = 0; j < operationFamilies.length; j++) {
      const operationFamily = operationFamilies[j];
      // console.log(operationFamily);
      const coefficients = operationFamily.coefficients;
      if (isFirstTime) {
        const opGroup = new OperationGroup(
          operationFamily.operationFamilyId,
          operationFamily.operationFamilyName,
          operationFamily.operationFamilyKey
        );
        operationGroups.push(opGroup);
      }
      // console.log(coefficients);
      const columnCoefficientsDefiningOperations: Array<any> = [];
      const columnCoefficients: Array<any> = [];
      const fieldCoefficient: Array<any> = [];

      for (let i = 0; i < coefficients.length; i++) {
        const coeff: Coefficient = coefficients[i];
        // console.log(coeff);
        let coefficientOptions = null;
        if (coeff.coefficientType !== CoefficientTypeEnum.T1) {
          coefficientOptions = (
            await CoefficientOptionService.GetCoefficientOptionsByCoefficient(
              coeff.id,
              this.$i18n.locale
            )
          ).data.map((el: any) => {
            const value =
              coeff.coefficientType == CoefficientTypeEnum.T2
                ? el.value
                : el.name;
            return {
              key: el,
              value,
            };
          });
        } else {
          this.haveT1Coefficients = true;
        }

        if (coeff.coefficientType === CoefficientTypeEnum.T4) {
          let opGlobalField: any = {};
          //  if (coeff.coefficientType == CoefficientTypeEnum.T0) {
          opGlobalField = {
            id: coeff.id,
            name: coeff.name,
            coeffKey: coeff.key,
            coefficientType: coeff.coefficientType,
            options: coefficientOptions,
          };

          globalCoefficients[coeff.key] = opGlobalField;
        }
        // if the coefficient is per line not per group
        else if (!coeff.isOperationGroupCoefficient) {
          if (coeff.coefficientType == CoefficientTypeEnum.T0) {
            columnCoefficients.push({
              name: "coefficientEditable",
              title: coeff.name,
              coefficient: coeff,
              property: `coefficientSelectedValues.${coeff.key}.value`,
              dataContainer: `coefficients.${coeff.key}.value`,
              isNotEditable: !this.isEditable,
            });
            fieldCoefficient.push({ key: coeff.key, id: coeff.id });
          } else if (
            coeff.coefficientType == CoefficientTypeEnum.T2 ||
            coeff.coefficientType == CoefficientTypeEnum.T3
          ) {
            fieldCoefficient.push({ key: coeff.key, id: coeff.id });

            if (
              !this.unitValuePointIsDefinedByCoefficient &&
              coeff.definesOperationUnitPoint
            ) {
              this.unitValuePointIsDefinedByCoefficient = true;
              columnCoefficientsDefiningOperations.push({
                name: "bopCoefficientDropDown",
                title: coeff.name,
                property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
                // display: "operationName",
                coefficient: coeff,
                // options: coefficientOptions,
                dataContainer: `coefficients.${coeff.key}`,
                isNotEditable: !this.isEditable,
              });
            } else {
              columnCoefficients.push({
                name: "bopCoefficientDropDown",
                title: coeff.name,
                property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
                // display: "operationName",
                coefficient: coeff,
                // options: coefficientOptions,
                dataContainer: `coefficients.${coeff.key}`,
                isNotEditable: !this.isEditable,
              });
            }
          }
        } else {
          // if it is a group coefficient
          let opGroupField: any = {};
          opGroupField = {
            id: coeff.id,
            name: coeff.name,
            coeffKey: coeff.key,
            coefficientType: coeff.coefficientType,
            property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
            dataContainer: `coefficients.${coeff.key}`,
          };
          fieldCoefficient.push({ key: coeff.key, id: coeff.id });

          if (
            coeff.coefficientType == CoefficientTypeEnum.T2 ||
            coeff.coefficientType == CoefficientTypeEnum.T3
          ) {
            opGroupField["options"] = coefficientOptions;
          }

          operationGroupCoefficientFields.push(opGroupField);
        }
      }

      const columns: Array<any> = [];
      if (this.isQuantityVisible) {
        columns.push({
          name: "quantity",
          title: this.$t("bopSetup.operationQuantity").toString(),
          property: "quantity",
          isNotEditable: !this.isEditable,
        });
      }
      if (!this.unitValuePointIsDefinedByCoefficient) {
        if (this.haveT1Coefficients) {
          columns.push({
            name: "disabledDynamicInput",
            title: this.$t("bopSetup.operationUnitPoints").toString(),
            property: "operationUnitPoints",
            formula: operationFamily.functionOperationUnitPoints,
            titleClass: "hidden",
            dataClass: "hidden",
          });
        } else {
          columns.push({
            name: "disabledDynamicInput",
            title: this.$t("bopSetup.operationUnitPoints").toString(),
            formula: "operation.operationUnitValue",
            titleClass: "hidden",
            dataClass: "hidden",
          });
        }
      } else {
        columns.push(...columnCoefficientsDefiningOperations);
        columns.push({
          name: "disabledDynamicInput",
          title: this.$t("bopSetup.operationUnitPoints").toString(),
          formula: "operation.operationUnitValue",
          titleClass: "hidden",
          dataClass: "hidden",
        });
      }
      columns.push(...columnCoefficients);

      columns.push({
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.total").toString(),
        property: "totalPoints",
        // formula: "{operation.operationUnitValue} * {quantity} * {coefficients.K1.value}",
        formula: operationFamily.functionUnitPoints,
      });
      columnsPerFamily[operationFamily.operationFamilyId] = columns;
      coefficientsPerFamily[
        operationFamily.operationFamilyId
      ] = fieldCoefficient;
    }
    // console.groupEnd();
    await this.$nextTick();

    this.operationGroupCoefficientFields = operationGroupCoefficientFields;
    // console.group("After the next tick");
    // console.log(this.bop.operationPrincipalGroups);
    this.globalCoefficients = globalCoefficients;
    // console.log(operationGroups);

    operationGroups = operationGroups.concat(
      this.bop.operationPrincipalGroups ?? []
    );
    this.operationGroups = operationGroups;
    this.bop.operationPrincipalGroups = operationGroups;

    const operationGroupOperationFamilyDictionary = {};
    this.operationGroups.forEach((og: OperationGroup) => {
      if (og.canBeDeleted) return;
      operationGroupOperationFamilyDictionary[<string>og.operationFamilyKey] =
        og.id;
      // if echafaudage load all operations
      if (this.isEchafaudage && !og.operations.length) {
        // console.log(coefficientsPerFamily);
        //loading the coefficients
        let coefficientSelectedValues: any = {};
        coefficientsPerFamily[og.operationFamilyId].forEach(
          (fieldCoefficient: any) => {
            coefficientSelectedValues[fieldCoefficient.key] = {
              coefficientId: fieldCoefficient.id,
            };
            if (fieldCoefficient.key === "Surface") {
              coefficientSelectedValues[fieldCoefficient.key].value = 0;
            }
          }
        );
        this.loadOperationsPrincipalesPerFamily(og.operationFamilyId).then(
          (operations: Array<any>) => {
            // console.log(operations);
            // console.log("for this group" + og.operationFamilyName);
            og.operations = operations.map((operation: any) => ({
              id: Guid.create().toString(),
              operationId: operation.id,
              coefficientSelectedValues: JSON.parse(
                JSON.stringify(coefficientSelectedValues)
              ),
              quantity: 1,
            }));
          }
        );
      }
    });
    if (this.isEchafaudage) {
      if (!Object.keys(this.selectedGlobalCoefficients).length) {
        const firstCoefficientOption = <any>(
          Object.values(this.globalCoefficients)[0]
        );
        Vue.set(
          this.selectedGlobalCoefficients,
          firstCoefficientOption.coeffKey,
          firstCoefficientOption.options[0].key.id
        );
      }
    }
    this.coefficientsPerFamily = coefficientsPerFamily;

    this.operationGroupOperationFamilyDictionary = operationGroupOperationFamilyDictionary;
    // console.log(columnsPerFamily);
    this.columnsPerFamily = columnsPerFamily;
    // console.groupEnd();
  }
  /**
   * Hooks
   */
  private mounted() {
    this.calculate();
  }

  private created() {
    if (!this.bop.bopModelId) return;

    this.loadData().then(() => {
      if(this.selectedBopModel && this.selectedBopModel.key === BopModelEnum.PeintureIndustrielle){
        // this.globalCoefficients
        INITIALIZED_GLOBAL_COEFFICIENTS.forEach(coeffKey=> {
          // console.log((coeffKey));
          // console.log(this.globalCoefficients);
          if(!this.selectedGlobalCoefficients[coeffKey])
            this.selectedGlobalCoefficients[coeffKey] = this.globalCoefficients[coeffKey].options.sort((a,b)=> a.key.value - b.key.value)[0].key.id
        })
      }
      // console.group("created");
      // console.log(this.operationGroups);
      // (window as any).operationPrincipal = this;
      // console.groupEnd();
    });
  }

  private async loadOperationsPrincipalesPerFamily(
    operationFamilyId
  ): Promise<Array<any>> {
    if(!this.selectedBopModel)return [];
    const operations = await OperationService.getBopOperations(
      this.selectedBopModel.id,
      1,
      this.$i18n.locale,
      null,
      null,
      operationFamilyId
    );
    return operations.data;
  }
  //watchers
  // @Watch("operationGroupsTotalPoints", { deep: true })
  // onOperationGroupsTotalPoints() {
  //   this.calculate();
  // }
  @Watch("bop.bopModelId")
  private onBopModelChange() {
    if(this.bop.bopModelId) {
      this.loadData();
    }
  }
  @Watch("selectedGlobalCoefficients", { deep: true })
  private onSelectedGlobalCoefficients() {
    if (this.isBopReadOnly) return;
    // console.log(this.globalCoefficients);
    // console.log(this.selectedGlobalCoefficients);
    let selectedGlobalCoeffs = {};
    //this.globalCoefficients.find(el=> el.options.key.id === this.selectedGlobalCoefficients))
    // this.bop.selectedGlobalCoefficients = this.selectedGlobalCoefficients;
    if (!Object.keys(this.globalCoefficients).length) return;

    for (let key in this.selectedGlobalCoefficients) {
      if (!this.globalCoefficients[key]) continue;
      const val = this.selectedGlobalCoefficients[key];
      const coeff = this.globalCoefficients[key].options.find(
        (op) => op.key.id === val
      )?.key;
      selectedGlobalCoeffs[key] = coeff;
      if (!this.bop.selectedGlobalCoefficients[key]) {
        this.bop.selectedGlobalCoefficients[key] = {};
      }
      this.bop.selectedGlobalCoefficients[key].coefficientOptionId = coeff?.id;
      this.bop.selectedGlobalCoefficients[key].coefficientId =
        coeff?.coefficientId;
      this.bop.selectedGlobalCoefficients[key].value = coeff?.value;
    }
    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      selectedGlobalCoeffs
    );
    this.calculate();
  }
}
